@import "~font-awesome/css/font-awesome.min.css";

*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  background-color: #FAFAFA; /* This is an off-white color; adjust as desired */
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
input,
button,
textarea,
select {
  font: inherit;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
#root,
#__next {
  isolation: isolate;
}
html,
body {
  cursor: default;
}
code {
  cursor: text;
}
a,
label,
button,
input[type="submit"],
input[type="button"],
input[type="radio"],
input[type="checkbox"] {
  cursor: pointer;
}
button[disabled],
input[disabled] {
  cursor: default;
}
:focus {
  outline: none;
}
ul, ol {
  list-style: none;
  padding: 0;
}
a {
  text-decoration: none;
}
* {
  font-family: 'Gluten', regular;
  font-family: 'Gluten', cursive;
  font-family: 'Roboto Serif', serif;
}
