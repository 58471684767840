.header-container {
  background-size: cover;
  background-position: center;
  height: 60vh;
  color: hsla(0, 0%, 100%, 0.888);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  padding-left: 10%;
  padding-top: 10%;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../assets/Blouses.webp");
}

.header-navigation {
  position: absolute;
  top: 10px;
  font-weight: bold;
  display: flex;
  text-decoration: none;
  font-size: 1.2em;
  color: hsla(0, 0%, 100%, 0.888);
}

.header-navigation > a:hover,
.header-navigation > button:hover {
  color: gray;
  cursor: pointer;
}

.header-navigation > button {
  background-color: transparent;
  border: 1px solid white;
  margin-right: 10px;
  padding: 5px 10px;
  transition: background-color 0.3s ease;
}

.header-navigation > button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.header-title {
  font-size: 3.5em;
  font-weight: bold;
  margin-bottom: 20px;
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}

.header-message {
  font-size: 3em;
  margin-top: 20px;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}

.header-submessage {
  margin-top: 10px;
  font-size: 2em;
  font-style: italic;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}

.header-navigation > button {
  background-color: transparent;
  border: 2px solid white;
  border-radius: 5px;
  margin-right: 10px;
  padding: 5px 15px;
  transition: all 0.3s ease;
  outline: none;
  font-size: 1.2em;
}

.header-navigation > button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: hsla(0, 0%, 100%, 0.9);
}

.header-navigation > .active-language {
  background-color: hsl(0deg 0% 100% / 70%);
}

.header-navigation > a {
  color: hsla(0, 0%, 100%, 0.888);
  text-decoration: none;
  margin-right: 20px;
}

.header-navigation > a:visited {
  color: hsla(0, 0%, 100%, 0.888);
}

.header-navigation {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.nav-links {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.nav-links > a {
  font-size: 1.2em;
  text-decoration: none;
  color: hsla(0, 0%, 100%, 0.888);
  border: none;
}

.nav-links > a:hover {
  color: gray;
}
.active-language {
    color: red;
}

/*mobile friendly  */

@media (max-width: 900px) {
  .header-container {
    height: 80vh;
    justify-content: space-evenly;
    display: flex;
    align-items: center;
  }

  .header-title {
    top: 10%;
    display: contents;
  }
  .main-sub-section {
    gap: 2rem;
    align-items: center;
    flex-direction: column;
    display: flex;
  }
}
