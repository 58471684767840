.social-icons {
  display: flex;
  justify-content: center;
  gap: 100px;
}

.social-icons a {
  color: #000;
  font-size: 100px;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #0077b5;
}

.social-icons {
    display: flex;
    /* gap: 100px; */
    width: 100%;
    justify-content: space-evenly;
}

@media (max-width: 900px) {
    .social-icons {
        gap: 0px;
    }


  }
