.section-title {
  text-align: center;
  font-weight: bolder;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 35px;
}
.main-section {
  display: flex;
  flex-direction: column;
}

.section-first,
.section-second,
.section-third {
  display: flex;
}
.text-block-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-bottom: 1em;
}
.first-title {
  margin-bottom: 2em;
}
.first-summary {
  max-width: 75%;
}

.image-container {
  max-width: 50%;
  max-height: 50%;
  padding: 6%;
  margin-bottom: 1em;
}

.section-second {
  flex-direction: row-reverse;
}

.main-sub-section .section {
  margin-bottom: -100px;
}
.first-title {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: 400;
  line-height: 1.6;
}

.main-sub-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: center;
}

.section-first,
.section-second,
.section-third {
  display: contents;
}

.image-container {
  max-width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  width: 70%;
  height: auto;
  padding: 5%;
}

.text-block-container {
  max-width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-first .text-block-container {
  order: 2;
}
.section-first .image-container {
  order: 1;
}

.section-second .text-block-container {
  order: 2;
}
.section-second .image-container {
  order: 2;
}

.section-third .text-block-container {
  order: 3;
}
.section-third .image-container {
  order: 2;
}

.section-title {
  text-align: center;
  font-weight: bolder;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-subcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 900px) {
  .main-sub-section {
    gap: 2rem;
    align-items: center;
    flex-direction: column;
    display: flex;
  }
  .section-first .image-container {
    order: 2;
  }
  .section-first .text-block-container {
    order: 1;
  }

  .section-second .text-block-container {
    order: 2;
  }
  .section-second .image-container {
    order: 1;
  }
  .section-third .text-block-container {
    order: 1;
  }
  .section-third .image-container {
    order: 2;
  }

  .contact-section {


    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 20vh;
  }
  .image-container img {
    width: 90%;
  }
}

@media (max-width: 500px) {
  .image-container img {
    width: 90%;

  }

}
